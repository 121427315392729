import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _81db1084 = () => interopDefault(import('../client/pages/kits/index.vue' /* webpackChunkName: "pages/kits/index" */))
const _0e400c64 = () => interopDefault(import('../client/pages/lojas/index.vue' /* webpackChunkName: "pages/lojas/index" */))
const _e2807f3c = () => interopDefault(import('../client/pages/mapa-do-site/index.vue' /* webpackChunkName: "pages/mapa-do-site/index" */))
const _1b64e982 = () => interopDefault(import('../client/pages/minha-conta/index.vue' /* webpackChunkName: "pages/minha-conta/index" */))
const _0f90b04b = () => interopDefault(import('../client/pages/motos/index.vue' /* webpackChunkName: "pages/motos/index" */))
const _57a119e4 = () => interopDefault(import('../client/pages/reset/index.vue' /* webpackChunkName: "pages/reset/index" */))
const _c8673436 = () => interopDefault(import('../client/pages/veiculos/index.vue' /* webpackChunkName: "pages/veiculos/index" */))
const _188ee770 = () => interopDefault(import('../client/pages/checkout/carrinho/index.vue' /* webpackChunkName: "pages/checkout/carrinho/index" */))
const _7f053bb0 = () => interopDefault(import('../client/pages/checkout/confirmacao/index.vue' /* webpackChunkName: "pages/checkout/confirmacao/index" */))
const _6fce0e7e = () => interopDefault(import('../client/pages/checkout/pagamento/index.vue' /* webpackChunkName: "pages/checkout/pagamento/index" */))
const _11f80d7d = () => interopDefault(import('../client/pages/minha-conta/enderecos/index.vue' /* webpackChunkName: "pages/minha-conta/enderecos/index" */))
const _69f69d57 = () => interopDefault(import('../client/pages/minha-conta/meus-dados/index.vue' /* webpackChunkName: "pages/minha-conta/meus-dados/index" */))
const _1ddf02fe = () => interopDefault(import('../client/pages/minha-conta/pedidos/index.vue' /* webpackChunkName: "pages/minha-conta/pedidos/index" */))
const _7375801b = () => interopDefault(import('../client/pages/usuario/cadastro/index.vue' /* webpackChunkName: "pages/usuario/cadastro/index" */))
const _541e4c9d = () => interopDefault(import('../client/pages/usuario/login/index.vue' /* webpackChunkName: "pages/usuario/login/index" */))
const _113193f5 = () => interopDefault(import('../client/pages/checkout/confirmacao/pix.vue' /* webpackChunkName: "pages/checkout/confirmacao/pix" */))
const _76bbe506 = () => interopDefault(import('../client/pages/produto/_id/_.vue' /* webpackChunkName: "pages/produto/_id/_" */))
const _22199cba = () => interopDefault(import('../client/pages/kit/_id/_.vue' /* webpackChunkName: "pages/kit/_id/_" */))
const _7854ff25 = () => interopDefault(import('../client/pages/lista/_.vue' /* webpackChunkName: "pages/lista/_" */))
const _19a65fa0 = () => interopDefault(import('../client/pages/informacoes-gerais/_.vue' /* webpackChunkName: "pages/informacoes-gerais/_" */))
const _6d588224 = () => interopDefault(import('../client/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/kits",
    component: _81db1084,
    name: "kits"
  }, {
    path: "/lojas",
    component: _0e400c64,
    name: "lojas"
  }, {
    path: "/mapa-do-site",
    component: _e2807f3c,
    name: "mapa-do-site"
  }, {
    path: "/minha-conta",
    component: _1b64e982,
    name: "minha-conta"
  }, {
    path: "/motos",
    component: _0f90b04b,
    name: "motos"
  }, {
    path: "/reset",
    component: _57a119e4,
    name: "reset"
  }, {
    path: "/veiculos",
    component: _c8673436,
    name: "veiculos"
  }, {
    path: "/checkout/carrinho",
    component: _188ee770,
    name: "checkout-carrinho"
  }, {
    path: "/checkout/confirmacao",
    component: _7f053bb0,
    name: "checkout-confirmacao"
  }, {
    path: "/checkout/pagamento",
    component: _6fce0e7e,
    name: "checkout-pagamento"
  }, {
    path: "/minha-conta/enderecos",
    component: _11f80d7d,
    name: "minha-conta-enderecos"
  }, {
    path: "/minha-conta/meus-dados",
    component: _69f69d57,
    name: "minha-conta-meus-dados"
  }, {
    path: "/minha-conta/pedidos",
    component: _1ddf02fe,
    name: "minha-conta-pedidos"
  }, {
    path: "/usuario/cadastro",
    component: _7375801b,
    name: "usuario-cadastro"
  }, {
    path: "/usuario/login",
    component: _541e4c9d,
    name: "usuario-login"
  }, {
    path: "/checkout/confirmacao/pix",
    component: _113193f5,
    name: "checkout-confirmacao-pix"
  }, {
    path: "/produto/:id?/*",
    component: _76bbe506,
    name: "produto-id-all"
  }, {
    path: "/kit/:id?/*",
    component: _22199cba,
    name: "kit-id-all"
  }, {
    path: "/lista/*",
    component: _7854ff25,
    name: "lista-all"
  }, {
    path: "/informacoes-gerais/*",
    component: _19a65fa0,
    name: "informacoes-gerais-all"
  }, {
    path: "/",
    component: _6d588224,
    name: "index"
  }],

  parseQuery: function(query) {
			const qs = require("qs")

			return qs.parse(query)
		},
  stringifyQuery: function(query) {
			const qs = require("qs")

			const result = qs.stringify(query)

			return result ? `?${result}` : ""
		},
  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
